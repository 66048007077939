@import url("./colors.css"); /* Импортируем базовые стили */

button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

button.small {
  height: 40px;
  padding: 0 24px;
  min-width: 100px;
}

button.medium {
  height: 48px;
  padding: 12px 32px;
}

button.big {
  height: 56px;
  padding: 0 32px;
}

button.small-icon {
  display: inline-flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

button.primary {
  background-color: #155263;
  box-sizing: border-box;
  color: white;
  border: none;
}

button.primary:hover {
  background-color: #7397a1;
}

button.primary:disabled {
  background-color: #d0dce0;
  cursor: unset;
}

button.secondary {
  border: 2px solid;
  border-color: var(--petrol-100);
  color: var(--petrol-100);
  /* background-color: white; */
}

button.secondary:hover {
  background-color: var(--petrol-20);
}

button.secondary:disabled {
  border-color: var(--petrol-20);
  color: var(--petrol-20);
}

button.secondary:disabled:hover {
  background-color: white;
  cursor: unset;
}

button.tertiary {
  color: var(--petrol-100);
  text-decoration: underline;
}

button.teritary:disabled {
  color: var(--petrol-20);
}

button.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Закругление углов */
}

button.full {
  width: 100%;
}

button.social-auth {
  font-weight: 500;
  flex: auto;
  height: 48px;
  border-style: solid;
  border-width: 0.5px;
  background-color: white;
  border-color: var(--grey-40);
}

button.add {
  border-radius: 8px;
  border: 1px solid var(--orange-100);
  padding: 0 24px;
  color: var(--orange-100);
}

button.add:hover {
  color: white;
  background-color: var(--orange-100);
}

/* button.outline {
} */

button.start {
  justify-content: flex-start;
}
