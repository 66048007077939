:root {
    --beige-100: rgba(255, 246, 232, 1);
    --beige-40: rgba(255, 251, 246, 1);
    --beige-60: rgba(255, 250, 241, 1);
    --beige-80: rgba(255, 248, 237, 1);
    --dark-grey-text: rgba(47, 45, 41, 1);
    --error-red: rgba(211, 60, 60, 1);
    --green-100: rgba(75, 124, 58, 1);
    --green-20: rgba(235, 247, 231, 1);
    --grey-10: rgba(241, 240, 239, 1);
    --grey-100: rgba(106, 99, 97, 1);
    --grey-20: rgba(225, 224, 223, 1);
    --grey-40: rgba(195, 193, 192, 1);
    --grey-60: rgba(166, 161, 160, 1);
    --grey-80: rgba(136, 130, 129, 1);
    --h1-font-family: "Bricolage Grotesque", Helvetica;
    --h1-font-size: 64px;
    --h1-font-style: normal;
    --h1-font-weight: 700;
    --h1-letter-spacing: 0px;
    --h1-line-height: 129.99999523162842%;
    --h2-font-family: "Bricolage Grotesque", Helvetica;
    --h2-font-size: 48px;
    --h2-font-style: normal;
    --h2-font-weight: 700;
    --h2-letter-spacing: 0px;
    --h2-line-height: 129.99999523162842%;
    --h3-font-family: "Bricolage Grotesque", Helvetica;
    --h3-font-size: 32px;
    --h3-font-style: normal;
    --h3-font-weight: 700;
    --h3-letter-spacing: 0px;
    --h3-line-height: 129.99999523162842%;
    --h4-font-family: "Bricolage Grotesque", Helvetica;
    --h4-font-size: 24px;
    --h4-font-style: normal;
    --h4-font-weight: 700;
    --h4-letter-spacing: 0px;
    --h4-line-height: 38.400001525878906px;
    --h5-font-family: "Bricolage Grotesque", Helvetica;
    --h5-font-size: 20px;
    --h5-font-style: normal;
    --h5-font-weight: 700;
    --h5-letter-spacing: 0px;
    --h5-line-height: 32px;
    --orange-100: rgba(255, 126, 85, 1);
    --orange-20: rgba(255, 229, 221, 1);
    --orange-40: rgba(255, 203, 187, 1);
    --orange-60: rgba(255, 178, 153, 1);
    --orange-80: rgba(255, 152, 119, 1);
    --peach: rgba(255, 200, 182, 1);
    --petrol-100: rgba(21, 82, 99, 1);
    --petrol-20: rgba(208, 220, 224, 1);
    --petrol-40: rgba(161, 186, 193, 1);
    --petrol-60: rgba(115, 151, 161, 1);
    --petrol-80: rgba(68, 117, 130, 1);
    --text-m-regular-font-family: "General Sans", Helvetica;
    --text-m-regular-font-size: 16px;
    --text-m-regular-font-style: normal;
    --text-m-regular-font-weight: 400;
    --text-m-regular-letter-spacing: 0px;
    --text-m-regular-line-height: 25.600000381469727px;
    --text-m-semibold-font-family: "General Sans", Helvetica;
    --text-m-semibold-font-size: 16px;
    --text-m-semibold-font-style: normal;
    --text-m-semibold-font-weight: 500;
    --text-m-semibold-letter-spacing: 0px;
    --text-m-semibold-line-height: 25.600000381469727px;
    --text-s-medium-font-family: "General Sans", Helvetica;
    --text-s-medium-font-size: 14px;
    --text-s-medium-font-style: normal;
    --text-s-medium-font-weight: 500;
    --text-s-medium-letter-spacing: 0px;
    --text-s-medium-line-height: 22.399999618530273px;
    --text-s-regular-font-family: "General Sans", Helvetica;
    --text-s-regular-font-size: 14px;
    --text-s-regular-font-style: normal;
    --text-s-regular-font-weight: 400;
    --text-s-regular-letter-spacing: 0px;
    --text-s-regular-line-height: 22.399999618530273px;
    --text-XS-medium-font-family: "General Sans", Helvetica;
    --text-XS-medium-font-size: 12px;
    --text-XS-medium-font-style: normal;
    --text-XS-medium-font-weight: 500;
    --text-XS-medium-letter-spacing: 0px;
    --text-XS-medium-line-height: 19.200000762939453px;
    --text-XS-regular-font-family: "General Sans", Helvetica;
    --text-XS-regular-font-size: 12px;
    --text-XS-regular-font-style: normal;
    --text-XS-regular-font-weight: 400;
    --text-XS-regular-letter-spacing: 0px;
    --text-XS-regular-line-height: 19.200000762939453px;
    --text-XXS-font-family: "General Sans", Helvetica;
    --text-XXS-font-size: 10px;
    --text-XXS-font-style: normal;
    --text-XXS-font-weight: 400;
    --text-XXS-letter-spacing: 0px;
    --text-XXS-line-height: 16px;
    --white: rgba(255, 255, 255, 1);
    --yellow-100: rgba(255, 185, 86, 1);
    --yellow-20: rgba(255, 241, 221, 1);
    --yellow-40: rgba(255, 227, 187, 1);
    --yellow-60: rgba(255, 213, 154, 1);
    --yellow-80: rgba(255, 199, 120, 1);
  }