.page {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: var(--Beige-40);
}

.header {
  top: 0;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 100%;
  background-color: white;
  z-index: 100;
  border-bottom: 1px solid var(--Grey-80);
  margin-bottom: 80px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1120px;
}

.headerlogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1120px;
  height: 100%;
  padding-bottom: 500px;
}

.headerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-height: 52px;
  padding: 0 20px;
  cursor: pointer;
}

.selectedBtn {
  background-color: var(--Beige-100);
}

.headerBtn:hover {
  background-color: var(--Beige-100);
}
