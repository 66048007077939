.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    flex: 1;
    width: 700px;
    display: flex;
    justify-content: center;
  }
}
