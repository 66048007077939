.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top_line {
  background-color: var(--orange-100);
  height: 30px;
  width: 100%;
  display: flex;
}

.form_container {
  margin-top: -100px;
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.logo_wrapper {
  width: 100%;
  display: flex;
  text-align: start;
}

.logo {
  margin-top: 40px;
  margin-left: 80px;
  width: 250px;
}

.separator {
  position: relative;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 1px;
  background-color: #ccc;
  top: 50%;
}

.separator::before {
  left: 0;
}

.separator::after {
  right: 0;
}

.right_image,
.left_image {
  z-index: -2;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.left_image {
  top: 0;
  left: 0;
}

.right_image {
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 1080px) {
  .right_image,
  .left_image {
    display: none;
  }
}
