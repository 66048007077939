.modal {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.404);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  opacity: 0;
  pointer-events: none;
  transition: 0.1s;
  overflow-y: auto;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  margin: 64px 20px;
  padding: 32px;
  border-radius: 8px;

  max-width: 1120px;
  /* max-height: 90%; */
  height: fit-content;

  /* max-height: 100%; */
  overflow-y: visible;

  form {
    gap: 25px;
    width: 100%;
    overflow-y: visible;
  }
}

@media screen and (max-width: 900px) {
  .content {
    padding: 10px;

    form {
      gap: 25px;
      width: 100%;
      overflow-y: visible;
    }
  }
}
