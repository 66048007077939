.page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_container {
  width: 400px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}

.logo {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  h4 {
    display: flex;
    color: var(--orange-80);
    margin-bottom: 20px;
    margin-top: -10px;
    width: 250px;
    align-self: flex-end;
  }
  img {
    height: 75px;
    width: 250px;
  }
}
