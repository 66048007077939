.roadList {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.roadItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--Petrol-100);
}

.roadItem:hover {
  background-color: var(--Yellow-80);
}

.cvGenWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  align-items: center;
}

@keyframes dots {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dots span {
  animation: dots 1.4s infinite;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.dots span {
  font-size: 16px;
  color: orange;
}
