.iscoSearchContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.inputContainer {
  margin-top: 8px;
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputArrow {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.dropdownContent {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  top: 115px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dropdownTable {
  width: 100%;
  border-collapse: collapse;
}

.table_isco {
  width: 80px;
  text-align: center !important;
}

.table_desc {
  word-wrap: break-word;  
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.dropdownTable th,
.dropdownTable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.dropdownTable th {
  background-color: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.dropdownTable tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.selectedItem {
  background-color: #e6f7ff !important;
}

.dropdownItem {
  cursor: pointer;
}

.dropdownItem:hover,
.selectedItem {
  background-color: #f0f0f0;
}

.radioList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
