.timeSlot {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px dashed var(--Grey-20, #e1e0df);
}

.timeSlot.selected {
  border: 1px solid var(--Petrol-100);
  color: var(--Petrol-100);
}

/* Table */
.tableWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 12px;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.tableWrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 82px;
  z-index: 0;
  background-color: var(--Beige-60);
  border-radius: 8px;
}

.table {
  display: flex;
  max-height: 100%;
  gap: 24px;
  z-index: 1;
}

.dayHeader {
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  padding: 16px 0;
  margin-bottom: 16px;

  p {
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80px;
    text-align: center;
  }
}

/*  */

.navigateBtn {
  height: 82px;
  z-index: 1;
  justify-content: center;
}

.selectedDateList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.selectedDateItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendarWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  h5 {
    margin-bottom: 32px;
  }
}

.control {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  justify-content: flex-end;
  gap: 24px;
  border-top: 1px solid var(--Grey-20, #e1e0df);
}

.inputTime {
  height: 34px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 80px;
  border: 1px solid var(--Petrol-100);
  color: var(--Petrol-100);
}

.timeAddBtn {
  height: 32px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid var(--Petrol-100);
  color: var(--Petrol-100);
  cursor: pointer;
  box-sizing: border-box;
}

.timePickerWrapper {
  display: flex;
  position: relative;
  box-sizing: border-box;
  margin: 8px 0;
}

.pickerContainer {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 120px;
  top: 40px;
  left: -20px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  overflow: visible;
}

.inputTime {
  font-size: 16px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.timeSelectors {
  display: flex;
  gap: 4px;
}

.scrollableList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.scrollableListItem {
  padding: 8px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.scrollableListItem:hover {
  background-color: var(--Petrol-80);
  color: white;
}

.selected {
  background-color: var(--Petrol-60);
  color: white;
}

/* Убираем кнопку выбора времени для Chrome, Safari и Edge */
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Убираем стрелки выбора времени для Firefox */
input[type="time"]::-moz-clear {
  display: none;
}

input[type="time"]::-moz-focus-inner {
  border: 0;
}

/* Убираем стандартное оформление для IE и Edge (старые версии) */
input[type="time"] {
  -moz-appearance: textfield;
}
