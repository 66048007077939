.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  width: 680px;
  margin: 50px auto;
  padding: 30px;
  text-align: center;
}

.right_image,
.left_image {
  z-index: -2;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.left_image {
  top: 0;
  left: 0;
}

.right_image {
  bottom: 0;
  right: 0;
}

.succes_msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  .right_image,
  .left_image {
    display: none;
  }
}
