.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.logo {
  height: 50px;
  width: 190px;
}

.block {
  margin-top: 60px;
  display: flex;
  gap: 30px;
  flex-direction: row;
}

.box {
  border-radius: 8px;
  background-color: var(--yellow-20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 460px;
  padding-top: 50px;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.box:hover {
  transform: scale(1.05); /* Увеличение при наведении */
}

.img {
  height: 90px;
  width: 90px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 950px) {
  .logo {
    display: none;
  }

  .img {
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
  }

  .block {
    flex-direction: column;
    margin-top: 0;
    gap: 15px;
  }

  .box {
    height: 300px;
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
  }
}
