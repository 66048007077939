.dropdown {
  position: absolute;
  box-sizing: border-box;
  top: 72px;
  right: 0;
  background-color: var(--petrol-100);
  border-radius: 8px;
  padding: 32px 0;
  z-index: 200;
  color: white;
  width: 380px;
}

.line {
  display: block;
  left: 0;
  right: 0;
  background-color: white;
  height: 1px;
  width: 100%;
}

.head {
  display: flex;
  flex-direction: column;
  margin: 0 10px 20px 32px;
  gap: 10px;
}

.list {
  padding-block: 10px;
}

.item {
  padding: 10px 32px;
  cursor: pointer;
}

.item:hover {
  background-color: var(--petrol-60);
}

.item:active {
  background-color: var(--petrol-40);
}

@media screen and (max-width: 1080px) {
  .dropdown {
    width: 260px;
    padding: 16px 0;
  }

  .head {
    margin: 0 10px 10px 32px;
    gap: 0px;
  }
}
