.column {
  /* background-color: #f4f5f7; */
  border-radius: 4px;
  gap: 16px;
  margin-right: 16px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  /* transition: background-color 0.3s ease; */
}

.items {
  min-width: 256px;
  min-height: 100px;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 4px;
  background-color: var(--Beige-80);
}

.active {
  border-radius: 8px;
  /* border: 10px dashed var(--Beige-100); */
  /* background-color: rgba(0, 0, 255, 0.3); */
  background-color: var(--Beige-100);
  /* transform: scale(1.05); */
  /* transition: transform 0.2s ease, background-color 0.3s ease; */
}
