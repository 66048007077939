.page {
  width: 100vw;
  display: flex;
  flex: 1;
  padding: 0 40px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_container {
  width: 100%;
  max-width: 440px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.logo {
  width: 175px;
  display: flex;
  text-align: start;
}

.separator {
  position: relative;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 1px;
  background-color: #ccc;
  top: 50%;
}

.separator::before {
  left: 0;
}

.separator::after {
  right: 0;
}

.right_image,
.left_image {
  z-index: -2;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.left_image {
  top: 0;
  left: 0;
}

.right_image {
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 1080px) {
  .right_image,
  .left_image {
    display: none;
  }

  .page {
    padding: 0 20px;
  }
}
