.container {
  /* padding: 20px; */
}

.highlight {
  color: #007bff;
}

.contentBlock {
  margin-top: 20px;
}

.status.open {
  color: green;
}

.status.closed {
  color: red;
}

.status.pending {
  color: orange;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

th,
td {
  padding: 10px;
  border: none; /* Убираем границы */
  text-align: left;
}

tbody tr {
  height: 50px;
  background-color: white;
  border-radius: 8px;
}

tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.selector {
  display: flex;
  border-bottom: 2px solid var(--Grey-20);
  justify-content: space-between;
}

.notification {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center; /* Текст по центру */
  color: var(--White);
  background-color: var(--ErrorRed);
  box-sizing: border-box;
  p {
    color: white !important;
  }
}

.selectorItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  gap: 8px;
  align-items: center;
  text-align: center;
  padding-bottom: 8px;
  position: relative;
  cursor: pointer;
  color: var(--dark-grey-text);
}

.selectorItem p {
  margin: 0;
}

.selectorItem.active {
  color: var(--petrol-100);
}

.selectorItem.active p {
  color: var(--petrol-100);
}

.selectorItem.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--petrol-100);
}

.talentPage {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.talentWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;
  z-index: 5;
}

.talentInfo {
  background-color: white;
  border: 1px solid var(--Grey-20, #e1e0df);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.fullBorder {
  border-radius: 8px;
}

.leftBorder {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  flex: 60%;
}

.rightBorder {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.chatWrapper {
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 650px;
  border: 1px solid var(--Grey-20, #e1e0df);
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  flex: 40%;
}

.hr {
  border-bottom: 1px solid var(--Grey-40);
}

.notize {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
}

.inputWrapper {
  border-radius: 8px;
  border: 1px solid var(--Grey-20, #e1e0df);
  background: var(--White, #fff);
}

.chatInput {
  width: 100%;
  height: 50px;
  resize: none;
  border: none;
}

.notes {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 24px 0;
  width: 100%;
  height: 100%;
  max-height: 505px;
  overflow: auto;
}

.talentList {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.talentItem {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--Grey-20, #e1e0df);
  background: var(--White, #fff);
  padding: 24px;
}

.contractField {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ebf7e7;
}
