.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0;
  border-bottom: 1px solid var(--Grey-20);
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.comment_item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--Grey-20);
  padding: 24px 0;
  gap: 16px;
}

.comment_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 500px;
  overflow: auto;
}
