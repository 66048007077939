.layout {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background: var(--Beige-40, #fffbf6);
  z-index: 1;
}

.containerWrapper {
  flex-direction: column;
  position: relative;
  align-items: center;
  display: flex;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
  /* padding: 0 40px; */
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: -webkit-fill-available;
  height: 100%;
  margin: 50px 40px;
}

.backgroundImage {
  position: absolute;
  top: 650px;
  right: -150px;
  z-index: -1;
  overflow: hidden; /* Ensure any overflow is hidden */
}

.hiden_sidebar {
  margin-left: 0;
}

@media screen and (max-width: 900px) {
  .container {
    margin: 50px 20px;
  }
}
