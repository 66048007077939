.footer {
  border-top: 1px solid var(--Grey-20, #e1e0df);
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px 40px;
  z-index: 10;
}

.container {
  display: flex;
  flex-direction: row;
  max-width: 1120px;
  width: 100%;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  gap: 8px 32px;
}

.logo {
  position: absolute;
  height: 32px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
}

.link:hover {
  color: var(--grey-80);
}

@media screen and (max-width: 900px) {
  .footer {
    padding: 12px 20px;
  }

  .footerLinks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    gap: 8px 16px;
  }
}
