/* Чтоб сайдбар норм работал как у apple (но не работает, и автоскрол падает) */
/* .kanbanContainer {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  overflow-x: auto;
} */

.kanban {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 700px;
  width: 1120px;
  overflow-x: auto;
  /* overflow-y: hidden; */
  padding-bottom: 32px;
}
