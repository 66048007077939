.page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top_line {
  background-color: var(--orange-100);
  height: 30px;
  width: 100%;
  display: flex;
}

.form_container {
  margin-top: -100px;
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.logo_wrapper {
  width: 100%;
  display: flex;
  text-align: start;
}

.logo {
  margin-top: 40px;
  margin-left: 80px;
  width: 250px;
}

.password_wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.password_icon {
  position: absolute;
  right: 18px;
  cursor: pointer;
  color: var(--Grey-60);
}

.checkbox input {
  margin-top: 0;
  margin-bottom: 0;
}
